import React, { Suspense } from 'react';
import endpoint, { colorEndpoint } from './endpoint';
import Multimap from 'multimap';
import { Container, Dimmer, Message, Table, TableProps, Loader } from 'semantic-ui-react';
import tinycolor from 'tinycolor2';
import Error from './Error';
const Screenshot = React.lazy(() => import('./Screenshot'));

interface Props {
    currentSchedCode?: string;
    currentStudent?: string;
    currentSemester?: string;
    hasScreenshotCallback: boolean;
    screenshotCallbackSetter: (callback: () => Promise<void>) => void;
}

interface State {
    loading: boolean;
    currentStudentData: string[];
    currentSchedulingData: string[];
    colour: string[];
    error?: string;
    ref: React.Ref<HTMLDivElement>;
    image?: string;
}

const range = (start: number, stop: number, step = 1) =>
    Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step as number);

const PERIODS_PER_DAY = 14, WEEKDAY_PER_WEEK = 6;
const weekday_names = [...Array(WEEKDAY_PER_WEEK).keys()]
    .map(
        d => new Intl.DateTimeFormat(navigator.language, { weekday: 'long' })
            .format(new Date(Date.UTC(2021, 5, d)))
    )

class Scheduler extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            currentStudentData: [],
            currentSchedulingData: [],
            colour: [],
            ref: React.createRef<HTMLDivElement>()
        };
    }

    ref = { current: null as any as HTMLDivElement };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: Props) {
        let keys = Object.keys(prevProps) as (keyof Props)[];
        if (keys.some((key) => prevProps[key] !== this.props[key])) this.load();
    }

    async load() {
        let loadColor = () => fetch(`${colorEndpoint}`)
            .then(r => r.json())
            .then(r => r.result as number[][])
            .then(colours => colours.map(colorValues => colorValues.map(_ => _.toString(16).padStart(2, '0')).join('')))
        if (!this.state.colour.length)
            Promise.all([...Array(2)].map(() => loadColor())).then(colours => this.setState({ colour: colours.flat(2) }));

        let { currentSemester, currentStudent, currentSchedCode } = this.props;
        if (currentSemester && currentStudent) {
            let studentRecords = await fetch(`${endpoint}/${currentSemester}/query?0=${currentStudent}`)
                .then(r => r.text())
                .then(txt => txt.split('\n').filter(Boolean))
                .then(txt => {
                    let students = new Set(txt.map(row => row.split(',')[0]));
                    if (students.size !== 1) {
                        this.setState({ error: `Có nhiều hơn 1 sinh viên ứng với mã sinh viên ${currentStudent}. Vui lòng thử lại.` });
                        return [];
                    }
                    return txt;
                });

            if (studentRecords.length && currentSchedCode) {
                let _ = studentRecords.map(
                    record => fetch(`${endpoint}/meta/class-schedules/${currentSchedCode}/${record.split(',')[5]}`)
                        .then(r => r.text())
                        .then(r => r.split('\n'))
                );
                let currentSchedulingData = (await Promise.all(_)).flat(1).map(_ => _.trim());
                this.setState({ currentStudentData: studentRecords, currentSchedulingData, loading: false })
            };
        }

    }

    render() {
        let { colour, error, loading } = this.state;
        let { currentSemester, currentStudent, screenshotCallbackSetter, hasScreenshotCallback } = this.props;


        if (!(currentSemester && currentStudent))
            return (
                <Container textAlign="center">
                    <Message negative>Không xác định được học kỳ/sinh viên để tạo thời khóa biểu.</Message>
                </Container>
            )

        if (error)
            return (
                <Container textAlign="center">
                    <Message negative>{error}</Message>
                </Container>
            )

        let periods = [...Array(PERIODS_PER_DAY)]
            .map(() => [...Array(WEEKDAY_PER_WEEK)].map(() => ({
                hasValue: false,
                class: null as typeof relevantClassData[0] | null,
                startingPeriod: false,
                index: -1
            })));

        let classes = new Multimap(
            this.state.currentStudentData.map(a => [
                a.split(',')[5], a.split(',')[7]
            ])
        );
        let relevantClassData = this.state.currentSchedulingData.filter(row => {
            let classId = row.split(',')[2], classGroup = row.split(',').pop();
            return classes.get(classId)?.includes(classGroup!) || (classes.has(classId) && classGroup?.toUpperCase() === 'CL');
        })
            .map(_class => {
                let [subject, credit, code, lecturer, studentCount, , weekday, period, room, group] =
                    _class.split(',').map(className => className.replaceAll('$', ','));
                return {
                    subject, credit, code, lecturer, studentCount, weekday,
                    period: range(...period.split('-').map((_, i) => (+_) + (+!!i)) as [number, number]).map(_ => _ - 1),
                    room, group
                }
            });

        for (let [__index, _class] of relevantClassData.entries()) {
            let weekday = +_class.weekday - 2;
            _class.period.forEach(
                (period, index) => periods[period][weekday] = { hasValue: true, class: _class, startingPeriod: !index, index: __index }
            );
        }

        let colorMapping = new Map(relevantClassData.map((_class, index) => [_class.code, colour[index % colour.length]]));
        return (
            <Dimmer.Dimmable dimmed={loading}>
                <Error>
                    <Suspense fallback={null}>
                        <Screenshot
                            hasScreenshotCallback={hasScreenshotCallback}
                            screenshotCallbackSetter={screenshotCallbackSetter}/>
                    </Suspense>
                </Error>
                <div ref={e => this.ref = { current: e! }}>
                    <Table celled columns={WEEKDAY_PER_WEEK + 1 as TableProps['columns']}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ textAlign: 'center' }} collapsing>Tiết</Table.HeaderCell>
                                {weekday_names.map(day => <Table.HeaderCell style={{ textAlign: 'center' }}>{day}</Table.HeaderCell>)}
                                <Table.HeaderCell style={{ textAlign: 'center' }}>Tiết bắt đầu</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {periods.map(
                                (days, i) => (
                                    <Table.Row>
                                        <Table.Cell textAlign="center" width={1} collapsing>{i + 1}</Table.Cell>
                                        {days.filter(a => a.startingPeriod || !a.hasValue)
                                            .map((_, i) => {
                                                let rowSpan = _.class?.period.length;
                                                let cellColour = '#' + colorMapping.get(_.class?.code!);
                                                return <Table.Cell
                                                    textAlign="center"
                                                    collapsing={!i}
                                                    style={{
                                                        backgroundColor: rowSpan ? cellColour : 'auto',
                                                        color: rowSpan ? (
                                                            tinycolor(cellColour).isLight() ? 'black' : 'white'
                                                        ) : 'auto'
                                                    }}
                                                    rowSpan={rowSpan || undefined}>
                                                    {_.class?.code && (
                                                        <>
                                                            <b>{_.class?.code}</b>
                                                            <br />
                                                            {_.class.subject}
                                                            <br />
                                                            <b>{_.class.lecturer}</b>
                                                            <br />
                                                            {_.class.group.toUpperCase() === 'CL'
                                                                ? 'Cả lớp'
                                                                : `Nhóm ${_.class.group}`} tại {_.class.room}
                                                        </>
                                                    )}
                                                </Table.Cell>
                                            })
                                        }
                                        <Table.Cell textAlign="center">{`${i + 7}`.padStart(2, '0')}:00</Table.Cell>
                                    </Table.Row>
                                )
                            )}
                        </Table.Body>
                    </Table>
                </div>
                <Dimmer inverted active={loading}>
                    <Loader inverted>
                        Đang truy vấn dữ liệu...
                    </Loader>
                </Dimmer>
            </Dimmer.Dimmable>
        )
    }
}

export default Scheduler;