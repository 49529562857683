import React from 'react';
import { Button, Menu, Icon, Dropdown, Label, Sticky, Popup } from 'semantic-ui-react';
import StudentTable from './Table';
import StudentScheduler from './Scheduler';
import { Route, Switch, withRouter, RouteComponentProps, matchPath, Link } from 'react-router-dom';
import endpoint from './endpoint';

interface State {
    semesters: { code: string, name: string, scheduleCode: string }[];
    currentSemester?: string;
    loading: boolean;
    currentStudent?: string;
    screenshooting?: boolean;
    screenshotCallback?: () => Promise<void>;
}

class App extends React.PureComponent<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            semesters: [],
            loading: true
        };
    }

    contextRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        fetch(`${endpoint}/meta/semester-names`)
            .then(r => r.text())
            .then(r => r.split('\n').filter(Boolean).map(line => {
                let [code, sched, ...name] = line.split(':');
                return { code, name: name.join(':'), scheduleCode: sched };
            }))
            .then(semesters => this.setState({ semesters, loading: false }));
    }

    setSemester = (code : string) => {
        this.setState({ currentSemester: code });
        this.props.history.push(`/peeks/${code}`);
    }

    render() {
        let { semesters, currentSemester, loading, currentStudent, screenshotCallback, screenshooting } = this.state;
        return (
            <div ref={this.contextRef}>
                <Sticky context={this.contextRef}>
                    <Menu tabular style={{ backgroundColor: 'white' }}>
                        <Menu.Item>
                            <b>Công cụ sinh thời khóa biểu</b>
                        </Menu.Item>
                        <Menu.Item active={!!matchPath(this.props.location.pathname, { path: '/peeks', exact: false })}>
                            <Link to={`/peeks/${currentSemester || ''}`}>Danh sách đăng ký học</Link>
                        </Menu.Item>
                        <Menu.Item active={!!matchPath(this.props.location.pathname, { path: '/schedule', exact: false })}>
                            <Link to={`/schedule/${currentSemester || ''}${currentStudent ? '/' + currentStudent : ''}`}>
                                Thời khóa biểu {currentStudent ? `cho sinh viên ${currentStudent}` : ''}
                            </Link>
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            <Menu.Item>
                                {screenshotCallback
                                    && (
                                        <Button
                                            icon labelPosition="left" basic positive disabled={screenshooting}
                                            onClick={() => {
                                            if (screenshotCallback) {
                                                this.setState({ screenshooting: true });
                                                screenshotCallback?.().then(() => this.setState({ screenshooting: false }))
                                            }
                                        }}>
                                            Chụp lại thời khóa biểu
                                            <Icon name="camera" />
                                        </Button>
                                    )}
                            </Menu.Item>
                            <Menu.Item>
                                <Dropdown
                                    text={semesters.find(_ => _.code === currentSemester)?.name ?? 'Chọn học kỳ'}
                                    loading={loading}
                                    lazyLoad>
                                    <Dropdown.Menu>
                                        {semesters.map(_ => (
                                            <Dropdown.Item onClick={() => this.setSemester(_.code)}>
                                                <Label>{_.code}</Label>{_.name}
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item disabled>
                                            Đổi học kỳ sẽ chuyển hướng về danh sách đăng ký học.
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                            <Menu.Item>
                                <Popup
                                    content={<>Viết bởi Võ Minh Đức.<br />Sử dụng React & Semantic UI.</>}
                                    trigger={<Button basic size="mini" icon="info" />}/>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Sticky>
                <Switch>
                    <Route path="/schedule/:currentSemester/:currentStudent?" render={props => {
                        let { currentStudent, currentSemester } = props.match.params;
                        if (this.state.currentSemester !== currentSemester || this.state.currentStudent !== currentStudent)
                            this.setState({ currentSemester, currentStudent });
                        return <StudentScheduler
                                currentSchedCode={semesters.find(_ => _.code === currentSemester)?.scheduleCode}
                                currentSemester={currentSemester}
                                currentStudent={currentStudent}
                                hasScreenshotCallback={!!screenshotCallback}
                                screenshotCallbackSetter={callback => this.setState({ screenshotCallback: callback })}/>
                    }}/>
                    <Route path="/peeks/:currentSemester?" render={props => {
                        let { currentSemester } = props.match.params;
                        if (this.state.screenshotCallback) this.setState({ screenshotCallback: undefined });
                        if (this.state.currentSemester !== currentSemester) this.setState({ currentSemester });
                        return <StudentTable schedulingPossible={true} currentSemester={currentSemester} />;
                    }}/>
                    <Route path="/" render={props => {
                        if (props.location.pathname === '/')
                            props.history.push(`/peeks/`)
                        return null;
                    }}/>
                </Switch>

            </div>
        )
    }
}

export default withRouter(App);