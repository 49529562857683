import React from 'react';
import { Table, Dimmer, Loader, Container, Form, Visibility, Button, Message } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { endpoint } from './endpoint';

const keys = [
    'Mã sinh viên', 'Họ (tên đệm)', 'Tên', 'Ngày sinh', 'Lớp khóa học',
    'Lớp môn học', 'Môn học', 'Nhóm', 'Số tín chỉ', 'Ghi chú'
]

interface State {
    peek: string[];
    query: [number, string][];
    table: JSX.Element | null;
    loading: boolean;
    once: boolean;
    onceQuery: boolean;
}

interface Props extends RouteComponentProps {
    currentSemester?: string;
    schedulingPossible: boolean;
}

class StudentTable extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            peek: [],
            query: [],
            loading: true,
            once: false,
            onceQuery: false,
            table: null,
        };
    }
    peeking = false;

    componentDidMount() {
        if (this.props.currentSemester) this.init();
        else this.setState({ loading: false });
    }

    componentDidUpdate(prevProps : Props) {
        if (prevProps.currentSemester !== this.props.currentSemester) {
            this.setState({ loading: true });
            if (!this.props.currentSemester)
                return this.setState({ loading: false, peek: [] });

            this.init();
            return;
        }
        if (prevProps.schedulingPossible !== this.props.schedulingPossible) {
            this.setState({ table: this.renderTable(this.state.peek) })
        }
    }

    init = () => fetch(`${endpoint}/${this.props.currentSemester}/peek?offset=0&count=150`)
        .then(r => r.text())
        .then(txt => txt.split('\n').filter(Boolean))
        .then(records => this.setState({ peek: records, loading: false, table: this.renderTable(records), once: true }));

    peekMore = () => {
        let { peek } = this.state, { currentSemester } = this.props;
        if (this.peeking) return;
        this.peeking = true;
        this.setState({ loading: true });
        fetch(`${endpoint}/${currentSemester}/peek?offset=${peek.length}&count=100`)
            .then(r => r.text())
            .then(r => peek.concat(r.split('\n').filter(Boolean)))
            .then(txt => this.setState({ peek: txt, loading: false, table: this.renderTable(txt) }))
            .then(() => this.peeking = false);
    }

    query = () => {
        let { query, loading, onceQuery } = this.state, { currentSemester } = this.props;
        if (loading) return;
        if (!query.length && !onceQuery) return;
        this.setState({ loading: true });
        let params = new URLSearchParams();
        for (let record of query) params.set(`${record[0]}`, record[1]);
        fetch(`${endpoint}/${currentSemester}/query?${params.toString()}`)
            .then(r => r.text())
            .then(r => r.split('\n').filter(Boolean))
            .then(records => this.setState({ peek: records, loading: false, table: this.renderTable(records), onceQuery: true }));

    }

    modifyQuery = (index : number, _query : string) => {
        let { query } = this.state;
        if (!keys[index]) return query;
        let out = new Map(query); _query = _query.trim() ? _query : _query.trim();
        if (!_query)
            out.delete(index);
        else
            out.set(index, _query);
        return [...out];
    }

    renderTable(peek? : string[]) {
        if (!peek) peek = this.state.peek;
        let { history, currentSemester } = this.props;
        return (
            <Table.Body>
                {peek.map((row, rowIndex, array) => (
                    <Table.Row>
                            {row.split(',').map((field, index) => {
                                let content = index
                                    ? field
                                    : (
                                        <Button
                                            onClick={() => history.push(`/schedule/${currentSemester}/${field}`)}
                                            color="black" compact basic
                                            disabled={!this.props.schedulingPossible}>
                                            {field}
                                        </Button>
                                    );
                                return (
                                    <Table.Cell>
                                        {!index && (rowIndex / array.length > 0.9) && !this.state.query.length
                                            ? <Visibility key={`row_${rowIndex}`} onOnScreen={() => this.peekMore()}>
                                                {content}
                                            </Visibility>
                                            : content}
                                    </Table.Cell>
                                )
                            })}
                    </Table.Row>
                ))}
            </Table.Body>
        )
    }

    render() {
        let { loading, peek, query, table, once } = this.state;
        let qmap = new Map(query);
        return (
            <>
                {(!!peek.length || once) && <Dimmer.Dimmable dimmed={loading}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                {keys.map((key, i) => (
                                    <Table.HeaderCell>
                                        <Form onSubmit={this.query}>
                                            <Form.Input
                                                transparent
                                                size="small"
                                                placeholder={key}
                                                value={qmap.get(i) || ''}
                                                onChange={(_, data) => this.setState({ query: this.modifyQuery(i, data.value) })}/>
                                        </Form>
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        {table}
                    </Table>
                    <Dimmer inverted active={loading}>
                        <Loader inverted>
                            Đang truy vấn dữ liệu...
                        </Loader>
                    </Dimmer>
                </Dimmer.Dimmable>}
                {!peek.length &&
                <Container textAlign="center">
                    <Message negative={!loading && once}>
                        {loading
                            ? 'Đang tải dữ liệu...'
                            : <>
                                {!once && <Message.Header>Bạn muốn xem danh sách của học kỳ nào?</Message.Header>}
                                {once
                                    ? 'Truy vấn không trả về kết quả - vui lòng thử lại.'
                                    : 'Vui lòng chọn học kỳ ở góc phải màn hình.'}
                            </>}
                    </Message>
                </Container>}
            </>
        )
    }
}

export default withRouter(StudentTable);