import React from 'react';

class Error extends React.PureComponent<{}, { error: boolean }> {
    constructor(props : {}) {
        super(props);
        this.state = { error: false }
    }

    componentDidCatch = () => this.setState({ error: true })
    render() { return this.props.children; }
}


export default Error
